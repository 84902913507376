<div class="ion-padding">

  <ion-list>
    @if (!!tokenWeb()) {
      <ion-item (click)="resetToken(canalTokenType.WEB)" lines="none">
        <ion-label style="font-size: 15px;" color="social-green" translate>NOTIFICATION.WEB_NOTIFICATIONS_ACTIVE</ion-label>
        <ion-button fill="outline" slot="end">
          <span translate>NOTIFICATION.RESET</span>
        </ion-button>
      </ion-item>
    }
    @if (!tokenWeb() && isWeb()) {
      <ion-item (click)="requestNotificationPermissionWeb()" lines="none">
        <ion-icon slot="start" name="notifications-circle-outline"></ion-icon>
        <ion-label translate>NOTIFICATION.WEB_NOTIFICATIONS</ion-label>
        <ion-button fill="outline" slot="end">
          <span translate>NOTIFICATION.ENABLE</span>
        </ion-button>
      </ion-item>
    }
    @if (!!tokenMobile()) {
      <ion-item (click)="resetToken(canalTokenType.MOBILE)" lines="none">
        <ion-label style="font-size: 15px;" color="social-green">
          <ion-label translate>NOTIFICATION.MOBILE_NOTIFICATIONS_ACTIVE</ion-label></ion-label>
          <ion-button fill="outline" slot="end">
            <span translate>NOTIFICATION.RESET</span>
          </ion-button>
        </ion-item>
      }
      @if (!tokenMobile() && !isWeb()) {
        <ion-item (click)="requestNotificationPermissionWithMobile()" lines="none">
          <ion-icon slot="start" name="notifications-circle-outline"></ion-icon>
          <ion-label translate>NOTIFICATION.MOBILE_NOTIFICATIONS</ion-label>
          <ion-button fill="outline" slot="end">
            <span translate>NOTIFICATION.ENABLE</span>
          </ion-button>
        </ion-item>
      }
    </ion-list>

  </div>