import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotificationRoutesEnum } from '@libs/entity-lib';
import { NotificationsComponent } from './notification-list/notification-list.component';
import { NotificationSettingsComponent } from './notification-settings/notification-settings.component';



export const routes: Routes = [
  {
    path: NotificationRoutesEnum.LIST,
    component: NotificationsComponent
  },
  {
    path: NotificationRoutesEnum.SETTINGS,
    component: NotificationSettingsComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class NotificationsLibRoutingModule {}
