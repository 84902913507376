import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import {  DialogResponse, DialogRoleEnumResponse } from '@libs/entity-lib';
@Injectable()
export class UtilService {

    constructor(private alertController: AlertController) {

    }

    async confirmationPopup(mensagem: string, cssClass = 'custom-alert'): Promise<DialogResponse> {
    const alert = await this.alertController.create({
      header: mensagem,
      cssClass,
      buttons: [
        {
          text: 'Não',
          role: DialogRoleEnumResponse.NO,
          cssClass: 'alert-button-cancel',
        },
        {
          text: 'Sim',
          role: DialogRoleEnumResponse.YES,
          cssClass: 'alert-button-confirm'
        },
      ],
    });
    alert.present();
    const { role, data } = await alert.onDidDismiss();
    return { role, data } as DialogResponse;
  }
}
