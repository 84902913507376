import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as fromNotificationActions from '../../store/notification/notification.actions';

@Injectable()
export class NotificationSettingsFacadeService {

  constructor(private store: Store) {
    
  }

  requestNotificationPermissionWithMobile(): void {
    this.store.dispatch(fromNotificationActions.requestPermissionsWithMobile());
  }

  requestNotificationPermissionWeb(): void {
    this.store.dispatch(fromNotificationActions.requestPermissionsWithWeb());
  }

}
