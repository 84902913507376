<ion-header class="ion-no-border">
  <ion-toolbar class="header-toolbar">
    <ion-buttons slot="start">
      <ion-back-button class="header-menu-button"></ion-back-button>
    </ion-buttons>
    <ion-title class="header-title" translate>NOTIFICATION.SETTING_NOTIFICATIONS</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">

  <div class="content-container">
    <div class="ion-text-center">
      <img src="assets/icon/colors/notification-settings.svg" width="90" />
    </div>

    <ion-card>
      <ion-card-content class="ion-no-padding">
        <notifications-lib></notifications-lib>
      </ion-card-content>
    </ion-card>

    @if (isLoading$ | async) {
      <ion-content class="ion-text-center">
        <ion-spinner name="dots"></ion-spinner>
      </ion-content>
    } @else {
      @if (notifications.length) {
        <ion-list>
          @for(notification of notifications; track notification.id) {
            <ion-item lines="full" lines="none">
              <ion-grid>
                <ion-row>
                  <ion-col>
                    <ion-label class="ion-text-wrap">{{ notification.description }}</ion-label>
                  </ion-col>
                </ion-row>
                <ion-row class="ion-no-padding">
                  <ion-col>
                    <ion-label class="checkbox-label">
                      <ion-checkbox [(ngModel)]="notification.activeMobile">Mobile</ion-checkbox>
                    </ion-label>
                  </ion-col>
                  <ion-col>
                    <ion-label class="checkbox-label">
                      <ion-checkbox [(ngModel)]="notification.activeWeb">Web</ion-checkbox>
                    </ion-label>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </ion-item>
          }
        </ion-list>
      }
      @if (!notifications.length) {
        <ion-card class="ion-padding">
          <span translate>NOTIFICATION.CONFIG_NOT_FOUND</span>
        </ion-card>
      }
      <div class="ion-text-center ion-padding-top">
        <ion-button [disabled]="!notifications.length" (click)="register(notifications)" color="primary" expand="block">
          <span translate>NOTIFICATION.REGISTER</span>
        </ion-button>
      </div>
    }

    <ng-template #content>

      @if (notifications.length) {
        <ion-list>
          @for(notification of notifications; track notification.id) {
            <ion-item lines="full" lines="none">
              <ion-grid>
                <ion-row>
                  <ion-col>
                    <ion-label class="ion-text-wrap">{{ notification.description }}</ion-label>
                  </ion-col>
                </ion-row>
                <ion-row class="ion-no-padding">
                  <ion-col>
                    <ion-label class="checkbox-label">
                      <ion-checkbox [(ngModel)]="notification.activeMobile">Mobile</ion-checkbox>
                    </ion-label>
                  </ion-col>
                  <ion-col>
                    <ion-label class="checkbox-label">
                      <ion-checkbox [(ngModel)]="notification.activeWeb">Web</ion-checkbox>
                    </ion-label>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </ion-item>
          }
        </ion-list>
      }

      @if (!notifications.length) {
        <ion-card class="ion-padding">
          <span translate>NOTIFICATION.CONFIG_NOT_FOUND</span>
        </ion-card>
      }

      <div class="ion-text-center ion-padding-top">
        <ion-button [disabled]="!notifications.length" (click)="register(notifications)" color="primary" expand="block">
          <span translate>NOTIFICATION.REGISTER</span>
        </ion-button>
      </div>


    </ng-template>
  </div>

</ion-content>
