import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { NotificationSettings } from '@libs/entity-lib';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { fetchAllNotificationsSettings, registerNotificationSettings } from '../../core/store/notification-settings/notification-settings.actions';
import { selectLoading, selectNotificationsSettings } from '../../core/store/notification-settings/notification-settings.selectors';
import { VibrationService } from '@libs/util-services';

@Component({
  selector: 'notifications-settings',
  templateUrl: 'notification-settings.component.html',
  styleUrls: ['notification-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationSettingsComponent implements OnInit, OnDestroy {

  notification$ = this.store.select(selectNotificationsSettings);
  notifications: NotificationSettings[] = [];
  isLoading$ = this.store.select(selectLoading);

  private subscriptions = new Subscription();

  constructor(private store: Store, private cdf: ChangeDetectorRef,
    private vibrationService: VibrationService) {
    this.fetchAllNotificationsSettings();
  }

  ngOnInit(): void {
    this.notification$.subscribe((notifications) => {
      if (notifications?.length) {
        this.notifications = JSON.parse(JSON.stringify(notifications)) ?? [];
        this.cdf.detectChanges();
      }
    });
  }

  fetchAllNotificationsSettings(): void {
    this.store.dispatch(fetchAllNotificationsSettings());
  }

  register(notificationsList: NotificationSettings[]): void {
    this.vibrationService.hapticsImpactMedium();
    this.store.dispatch(registerNotificationSettings({
      notifications: {
        notifications: notificationsList
      }
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

}
