import { combineReducers } from "@ngrx/store";

import { reducerSettings } from './notification-settings/notification-settings.reducer';
import { reducer } from './notification/notification.reducer';
import * as fromNotification from './notification/notification.reducer';
import * as fromNotificationSettings from './notification-settings/notification-settings.reducer';

export interface NotificationState {
    notifications: fromNotification.NotificationState,
    notificationsSettings: fromNotificationSettings.NotificationSettingsState
}

export const rootReducer = combineReducers({
    notifications: reducer,
    notificationsSettings: reducerSettings
  });
  