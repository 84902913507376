import { createAction, props } from '@ngrx/store';
import { CanalTokenType, Notification, NotificationRegisterRequest, NotificationToken } from '@libs/entity-lib';

export const fetchAllNotifications = createAction(
  '[NOTIFICATION-LIB] Fetch All Notifications'
);

export const fetchAllNotificationsSuccessfully = createAction(
  '[NOTIFICATION-LIB] Fetch All Notifications Successfuly ',
  props<{ notificationList: Notification[] }>()
);

export const fetchAllNotificationsFailed = createAction(
  '[NOTIFICATION-LIB] Fetch All Notifications Failed',
  props<{ error: any }>()
);

export const addNotification = createAction(
  '[NOTIFICATION-LIB] Add Notifications ',
  props<{ notification: Notification }>()
);

export const removeNotificationById = createAction(
  '[NOTIFICATION-LIB] Remove Notification By Id',
  props<{ id: string }>()
);

export const readNotification = createAction(
  '[NOTIFICATION-LIB] Read Notification',
  props<{ notification: Notification }>()
);

export const readNotificationsSuccessfully = createAction(
  '[NOTIFICATION-LIB] Read Notification Successfuly ',
  props<{ notification: Notification }>()
);

export const readNotificationsFailed = createAction(
  '[NOTIFICATION-LIB] Read Notification Failed',
  props<{ error: any }>()
);

export const setLoading = createAction(
  '[NOTIFICATION-LIB] Set Loading',
  props<{ loading: boolean }>()
);

export const registerToken = createAction(
  '[NOTIFICATION-LIB] Register token',
  props<{ notificationRequest: NotificationRegisterRequest }>()
);

export const registerTokenSuccessfully = createAction(
  '[NOTIFICATION-LIB] Register token Successfuly '
);

export const registerTokenFailed = createAction(
  '[NOTIFICATION-LIB] Register token Failed',
  props<{ error: any }>()
);

export const requestPermissionsWithMobile = createAction(
  '[NOTIFICATION-LIB] Request Permission With Mobile'
);

export const requestPermissionsWithMobileFailed = createAction(
  '[NOTIFICATION-LIB] Request Permission With Mobile Failed',
  props<{ error: any }>()
);

export const requestPermissionsWithMobileNotAllowed = createAction(
  '[NOTIFICATION-LIB] Request Permission With Mobile Not Allowed',
  props<{ error: any }>()
);

export const requestPermissionsWithWeb = createAction(
  '[NOTIFICATION-LIB] Request Permission With Web'
);

export const registerTokenWebSuccess = createAction(
  '[NOTIFICATION-LIB] Register Token Web Success',
  props<{ token: string }>()
);

export const registerTokenMobileSuccess = createAction(
  '[NOTIFICATION-LIB] Register Token Mobile Success',
  props<{ token: string }>()
);


export const getToken = createAction(
  '[NOTIFICATION-LIB] Get Token'
);


export const readAllNotifications = createAction(
  '[NOTIFICATION-LIB] Read All Notification'
);

export const readAllNotificationsSuccessfully = createAction(
  '[NOTIFICATION-LIB] Read All Notification Successfuly '
);

export const readAllNotificationsFailed = createAction(
  '[NOTIFICATION-LIB] Read All Notification Failed',
  props<{ error: any }>()
);


export const fetchNotificationTokens = createAction(
  '[NOTIFICATION-LIB] Fetch Notifications Tokens'
);

export const fetchNotificationTokenSuccessfully = createAction(
  '[NOTIFICATION-LIB] Fetch Notifications Tokens Successfuly ',
  props<{ notificationToken: NotificationToken }>()
);

export const fetchNotificationTokenFailed = createAction(
  '[NOTIFICATION-LIB] Fetch Notifications Tokens Failed',
  props<{ error: any }>()
);

export const resetToken = createAction(
  '[NOTIFICATION-LIB] Reset Token',
  props<{ notificationRequest: NotificationRegisterRequest }>()
);

export const resetTokenSuccessfully = createAction(
  '[NOTIFICATION-LIB] Reset Token Successfully',
  props<{ token: string | null, canalTokenType: CanalTokenType }>()
);

export const resetTokenFailed = createAction(
  '[NOTIFICATION-LIB] Reset Token Failed',
  props<{ error: any }>()
);