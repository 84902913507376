import { Injectable, OnDestroy } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { Capacitor } from '@capacitor/core';
import {
  ActionPerformed,
  PushNotifications,
  PushNotificationSchema,
  Token,
} from '@capacitor/push-notifications';
import { ToastController } from '@ionic/angular';
import { CanalTokenType, NotificationRegisterRequest } from '@libs/entity-lib';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Subscription } from 'rxjs';
import * as fromNotificationActions from '../../store/notification/notification.actions';


@Injectable({
  providedIn: 'root'
})
export class NotificationListenersService implements OnDestroy {

  private token$$ = new BehaviorSubject<any>(null);
  token$ = this.token$$.asObservable();

  private subscriptions = new Subscription();

  constructor(private store: Store, private toastController: ToastController,
    private angularFireMessaging: AngularFireMessaging) {
  }

  init() {
    this.checkPlatform();
  }
  
  private checkPlatform(): void {
    if(Capacitor.getPlatform() !== 'web') {
      this.initialisePushNotifications();
    } else {
        this.messages();
    }
  }

  private initialisePushNotifications() {
    PushNotifications.addListener('registration', (token: Token) => {
      const notificationRequest = {token: token.value, canalTokenType: CanalTokenType.MOBILE } as NotificationRegisterRequest;
      this.presentToastTop('Configuração concluída! Você começará a receber notificações em breve.', 2500,'success');
      this.store.dispatch(fromNotificationActions.registerTokenMobileSuccess({ token: token.value }));
      this.store.dispatch(fromNotificationActions.registerToken({ notificationRequest }));
    });

    PushNotifications.addListener('registrationError', (error: any) => {
      this.presentToastTop('Error ao ativar notificações', 2500,'error');
      this.store.dispatch(fromNotificationActions.fetchAllNotificationsFailed({ error }));
    });

    PushNotifications.addListener(
      'pushNotificationReceived',
      (notification: PushNotificationSchema) => {
        console.log('notification: ', notification);
      },
    );

    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      (notification: ActionPerformed) => {
        console.log('notification: ', JSON.stringify(notification));
      },
    );
  }

  private messages() {
    this.subscriptions.add(
      this.angularFireMessaging.messages
      .subscribe({
        next: (messagePayload) => {
          this.token$$.next(JSON.stringify(messagePayload));
        },
        error: (error: any) => {
          this.token$$.next(JSON.stringify(error));
          console.error('Error getting message:', error);
        }
      })
    );
  }

  async presentToastTop(msg: string, dur: number, col: string) {
    const toast = await this.toastController.create({
      message: msg,
      duration: dur,
      position: 'bottom',
      color: col
    });

    await toast.present();
  }

  ngOnDestroy(): void {
      this.subscriptions.unsubscribe();
  }
}