import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Store } from '@ngrx/store';
import { fetchAllNotifications, readAllNotifications } from '../../core/store/notification/notification.actions';
import { selectLoading, selectNotifications } from '../../core/store/notification/notification.selectors';
import { Router } from '@angular/router';
import { Canal, getNotificationSettingsPage } from '@libs/entity-lib';
import { selectCanal } from '../../core/store/settings/settings.selectors';
import { VibrationService } from '@libs/util-services';

@Component({
  selector: 'notifications',
  templateUrl: 'notification-list.component.html',
  styleUrls: ['notification-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationsComponent {

  canal = this.store.selectSignal(selectCanal);
  canalType = Canal;

  notification$ = this.store.select(selectNotifications);
  isLoading$ = this.store.select(selectLoading);

  constructor(private store: Store, private router: Router,
    private vibrationService: VibrationService) {
    this.fetchAllNotifications();
  }

  fetchAllNotifications(): void {
    this.store.dispatch(fetchAllNotifications());
  }

  readAllNotifications(): void {
    this.vibrationService.hapticsImpactMedium();
    this.store.dispatch(readAllNotifications());
  }

  redirectToNotificationSettings(): void {
    this.vibrationService.hapticsImpactLight();
    this.router.navigate([getNotificationSettingsPage()]);
  }

}
