import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  finalize, map, of,
  switchMap,
  tap
} from 'rxjs';
import * as fromNotificationActions from './notification-settings.actions';
import { Store } from '@ngrx/store';
import { NotificationService } from '../../services/notification/notification.service';
import { ToastController } from '@ionic/angular';

@Injectable()
export class NotificationSettingsEffects {

  fetchAllNotificationsSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromNotificationActions.fetchAllNotificationsSettings),
      tap(() => this.showLoader()),
      switchMap(() =>
        this.notificationService.fetchAllSettings().pipe(
          map((notificationList) => fromNotificationActions.fetchAllNotificationsSettingsSuccessfully({ notificationList })),
          catchError((error) => of(fromNotificationActions.fetchAllNotificationsSettingsFailed({ error }))),
          finalize(() => this.hideLoader())
        )
      )
    )
  );

  registerNotificationSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromNotificationActions.registerNotificationSettings),
      tap(() => this.showLoader()),
      switchMap(({ notifications }) =>
        this.notificationService.registerNotificationSettings(notifications).pipe(
          map((notificationList) => {
            this.presentToastTop('Notificações configuradas com sucesso! :)', 1500, 'success');
            return fromNotificationActions.registerNotificationSettingsSuccessfully({ notificationList });
          }),
          catchError((error) => {
            this.presentToastTop('Falha o sincronizar notificações! :(', 2500, 'danger');
            return of(fromNotificationActions.registerNotificationSettingsFailed({ error }))
          }),
          finalize(() => this.hideLoader())
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private notificationService: NotificationService,
    private store: Store,
    private toastController: ToastController
  ) {}

  private async showLoader() {
    this.store.dispatch(fromNotificationActions.setLoading({ loading: true }));
  }

  private hideLoader() {
    this.store.dispatch(fromNotificationActions.setLoading({ loading: false }));
  }

  async presentToastTop(msg: string, dur: number, col: string) {
    const toast = await this.toastController.create({
      message: msg,
      duration: dur,
      position: 'bottom',
      color: col
    });

    await toast.present();
  }

}
