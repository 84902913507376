import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { Notification } from '@libs/entity-lib';
import { Store } from '@ngrx/store';
import { readNotification } from '../../../core/store/notification/notification.actions';

@Component({
  selector: 'notification-card',
  templateUrl: './notification-card.component.html',
  styleUrls: ['./notification-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationCardComponent {
  notification = input<Notification>();

  constructor(private store: Store) {}

  readNotification() {
    const notification = this.notification();
    if (!notification) {
      return;
    }
    this.store.dispatch(readNotification({ notification }));
  }
}
