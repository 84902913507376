import { createAction, props } from '@ngrx/store';
import { NotificationSettings, PutNotificationSettings } from '@libs/entity-lib';

export const fetchAllNotificationsSettings = createAction(
  '[NOTIFICATION-LIB] Fetch All Notifications Settings'
);

export const fetchAllNotificationsSettingsSuccessfully = createAction(
  '[NOTIFICATION-LIB] Fetch All Notifications Settings Successfuly',
  props<{ notificationList: NotificationSettings[] }>()
);

export const fetchAllNotificationsSettingsFailed = createAction(
  '[NOTIFICATION-LIB] Fetch All Notifications Settings Failed',
  props<{ error: any }>()
);

export const setLoading = createAction(
  '[NOTIFICATION-LIB] Set Loading',
  props<{ loading: boolean }>()
);

export const registerNotificationSettings = createAction(
  '[NOTIFICATION-LIB] Register All Notification Settings',
  props<{ notifications: PutNotificationSettings }>()
);

export const registerNotificationSettingsSuccessfully = createAction(
  '[NOTIFICATION-LIB] Register All Notifications Settings Successfuly',
  props<{ notificationList: NotificationSettings[]  }>()
);

export const registerNotificationSettingsFailed = createAction(
  '[NOTIFICATION-LIB] Register All Notifications Settings Failed',
  props<{ error: any }>()
);

