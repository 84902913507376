import { createFeatureSelector, createSelector } from '@ngrx/store';

import { SettingsStateKeys, Canal } from '@libs/entity-lib';
import * as fromSettings from '@libs/settings-state';

export const getSettingsState = createFeatureSelector<fromSettings.State>(
  SettingsStateKeys.SETTINGS_SOCIAL_SPORT
);

export const selectCanal = createSelector(
  getSettingsState,
  (state): Canal => state?.canal ?? Canal.UNDEFINED
);
