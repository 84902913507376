@if (notification(); as notification) {
  <ion-card>
    <ion-card-content>
      <p>{{ notification.message }}</p>
      <ion-button fill="outline" expand="full" (click)="readNotification()">
        <ion-icon slot="start" name="checkmark-circle-outline"></ion-icon>
        <span translate>NOTIFICATION.MARK_AS_READ</span>
      </ion-button>
    </ion-card-content>
  </ion-card>
}