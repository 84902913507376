import { NgModule } from '@angular/core';
import { NotificationService } from './services/notification/notification.service';
import { UtilService } from './utils/utils.service';
import { NotificationSettingsFacadeService } from './services/notification-settings/notification-settings-facade.service';

@NgModule({
  declarations: [],
  imports: [],
  providers: [
    NotificationService,
    UtilService,
    NotificationSettingsFacadeService
  ]
})
export class CoreModule { }
