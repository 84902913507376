import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatButtonModule } from '@angular/material/button';
import { IonicModule } from '@ionic/angular';
import { StoreModule } from '@ngrx/store';

import { EffectsModule } from '@ngrx/effects';
import { coreEffectsAtBootstrap } from '../core/store/core.effects';
import { CoreModule } from '../core/core.module';
import { SettingsStateKeys } from '@libs/entity-lib';
import { NotificationsComponent } from './notification-list/notification-list.component';
import { NotificationsLibRoutingModule } from './notifications-lib-routing.module';
import { NotificationLibComponent } from './notifications-lib.component';
import { NotificationCardComponent } from './notification-list/notification-card/notification-card.component';
import { NotificationSettingsComponent } from './notification-settings/notification-settings.component';
import { rootReducer } from '../core/store/index';
import { TranslationLibModule } from '@libs/translation';


@NgModule({
  declarations: [
    NotificationLibComponent,
    NotificationsComponent,
    NotificationCardComponent,
    NotificationSettingsComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    StoreModule.forFeature(SettingsStateKeys.NOTIFICATIONS, rootReducer),
    EffectsModule.forFeature(coreEffectsAtBootstrap),
    CoreModule,
    NotificationsLibRoutingModule,
    TranslationLibModule
  ],
  exports: [
    NotificationLibComponent,
    NotificationsComponent,
    NotificationSettingsComponent
  ],
  providers: [
  ]
})
export class NotificationsLibModule { 

}
