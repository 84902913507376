
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectAll } from './notification-settings.reducer';
import { SettingsStateKeys } from '@libs/entity-lib';
import { NotificationState } from '../index';

export const selectNotificationGlobalState =
  createFeatureSelector<NotificationState>(SettingsStateKeys.NOTIFICATIONS);

// Notifications Settings selector
export const selectNotificationSettingsState = createSelector(
  selectNotificationGlobalState,
  (state: NotificationState) => state.notificationsSettings
);

export const selectLoading = createSelector(
  selectNotificationSettingsState,
  (state): boolean => state?.loading ?? false
);

// Select all employees
export const selectNotificationsSettings = createSelector(selectNotificationSettingsState, selectAll);