import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as fromActions from './notification.actions';
import { CanalTokenType, Notification } from '@libs/entity-lib';

export interface NotificationState extends EntityState<Notification> {
  // Outras propriedades de estado que você pode precisar adicionar
  error: any | null;
  loading: boolean;
  tokenWeb: string | null;
  tokenMobile: string | null;
}

export const adapter = createEntityAdapter<Notification>();

export const initialState: NotificationState = adapter.getInitialState({
  error: null,
  loading: false,
  tokenWeb: null,
  tokenMobile: null
});

export const reducer = createReducer(
  initialState,
  on(fromActions.fetchAllNotificationsSuccessfully, (state, { notificationList }) => adapter.addMany(notificationList, state)),
  on(fromActions.readAllNotificationsSuccessfully, (state) => adapter.removeAll(state)),
  on(fromActions.addNotification, (state, { notification }) => adapter.addOne(notification, state)),
  on(fromActions.registerTokenMobileSuccess, (state, { token }) => ({...state, tokenMobile: token })),
  on(fromActions.registerTokenWebSuccess, (state, { token }) => ({...state, tokenWeb: token })),
  on(fromActions.resetTokenSuccessfully, (state, { canalTokenType, token }) => {
    switch(canalTokenType) {
      case CanalTokenType.WEB:
        return {...state, tokenWeb: token };
      case CanalTokenType.MOBILE:
        return {...state, tokenMobile: token };
      default:
        return state;
    }
  }),
  on(fromActions.fetchNotificationTokenSuccessfully, (state, { notificationToken }) => ({...state, tokenWeb: notificationToken.tokenNotificationWeb, tokenMobile: notificationToken.tokenNotificationMobile })),
  on(fromActions.removeNotificationById, (state, { id }) => adapter.removeOne(id, state))
);

export const { selectAll } = adapter.getSelectors();