import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as fromActions from './notification-settings.actions';
import { NotificationSettings } from '@libs/entity-lib';

export interface NotificationSettingsState extends EntityState<NotificationSettings> {
  // Outras propriedades de estado que você pode precisar adicionar
  error: any | null;
  loading: boolean;
}

export const adapter = createEntityAdapter<NotificationSettings>();

export const initialState: NotificationSettingsState = adapter.getInitialState({
  error: null,
  loading: false
});

export const reducerSettings = createReducer(
  initialState,
  on(fromActions.fetchAllNotificationsSettingsSuccessfully, (state, { notificationList }) => adapter.addMany(notificationList, state)),
  on(fromActions.registerNotificationSettingsSuccessfully, (state, { notificationList }) => adapter.setAll(notificationList, state)),
  on(fromActions.setLoading, (state, { loading }) => ({ ...state, loading })),
);

export const { selectAll } = adapter.getSelectors();